import React from 'react';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import './hero.css';

const Hero = () => (
  <section id="hero" className="d-flex align-items-center">
    <Fade>
      <div className="container text-center position-relative">
        <h1>We build and fund retail property in underserved areas.</h1>
        <h2>We build and fund retail property in underserved areas.</h2>
        <Link
          className="btn-get-started"
          to="about"
          smooth
          offset={-70}
          duration={750}
        >
          Get Started
        </Link>
      </div>
    </Fade>
  </section>
);

export default Hero;
