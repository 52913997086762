import React from 'react';
import Fade from 'react-reveal/Fade';
import { BiMap, BiEnvelope, BiPhone } from 'react-icons/bi';
import './contact.css';

const Contact = () => (
  <section id="contact" className="contact">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="section-title">
            <h2>Contact</h2>
            <p>
              Lets build and fund retail properties in underserved areas together.
              Contact us if your interested.
            </p>
          </div>
        </div>

        <div className="col-lg-8" data-aos="fade-up">
          <Fade top>
            <div className="info mt-4">
              <div className="icon-box">
                <BiMap />
              </div>
              <h4>Location:</h4>
              <p>
                1st Floor, Gateway West, 22 Magwa Cres, Waterval City, Johannesburg, 2066
              </p>
            </div>
          </Fade>
          <div className="row">
            <div className="col-lg-6 mt-4">
              <Fade left>
                <div className="info">
                  <div className="icon-box">
                    <BiEnvelope />
                  </div>
                  <h4>Email:</h4>
                  <p>info@example.com</p>
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <Fade right>
                <div className="info w-100 mt-4">
                  <div className="icon-box">
                    <BiPhone />
                  </div>
                  <h4>Call:</h4>
                  <p>+27 55 895 5488</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
);

export default Contact;
