import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, animateScroll as scroll } from 'react-scroll';
import './header.css';
import logo from './logo.svg';

const scrollTop = () => {
  scroll.scrollToTop();
};

const Header = () => {
  let listener = null;
  const [isTop, setIsTop] = useState(false);
  useEffect(() => {
    listener = document.addEventListener('scroll', () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (!isTop) {
          setIsTop(true);
        }
      } else if (isTop) {
        setIsTop(false);
      }
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isTop]);
  return (

    <header id="header" className={`fixed-top align-items-center ${isTop && 'header-scrolled'}`}>
      <div className="container">
        <div className="header-container align-items-center justify-content-between">
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="#hero" onClick={scrollTop} className="logo"><img src={logo} alt="logo" className="img-fluid" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="nav-menu justify-content-end">
              <Nav defaultActiveKey="#hero" as="ul" className="justify-content-end">
                <Nav.Item as="li">
                  <Link
                    activeClass="active"
                    to="hero"
                    spy
                    smooth
                    offset={-70}
                    duration={750}
                  >
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    activeClass="active"
                    to="about"
                    spy
                    smooth
                    offset={-70}
                    duration={750}
                  >
                    About
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    activeClass="active"
                    to="portfolio"
                    spy
                    smooth
                    offset={-70}
                    duration={750}
                  >
                    Work
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    activeClass="active"
                    to="contact"
                    spy
                    smooth
                    offset={-70}
                    duration={750}
                  >
                    Contact
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
