import React from 'react';
import './footer.css';

const Footer = () => (
  <footer id="footer">
    <div className="container py-4">
      <div className="me-md-auto text-center text-md-start justifiy-content-end">
        <div className="copyright">
          &copy; Copyright 2021
          {' '}
          <strong><span>African Profient Capital</span></strong>
          . All Rights Reserved.
        </div>
        <div className="credits">
          Developed by
          {' '}
          <a href="https://yelah.co.za/">Yelah</a>
          .
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
