/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Zoom from 'react-reveal/Zoom';
import './portfolio.css';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';

const Portfolio = () => (
  <section id="portfolio" className="portfolio">
    <div className="container">

      <div className="section-title">
        <h2>Recent Works</h2>
        <p>
          Building these properties was such a joy.
        </p>
      </div>

      <div className="row portfolio-container">
        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img1} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>

        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img2} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>
        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img3} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>
        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img4} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>
        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img5} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>
        <Zoom>
          <div className="col-lg-4 col-md-6 portfolio-item">
            <div className="portfolio-wrap">
              <img src={img6} className="img-fluid" alt="" />
            </div>
          </div>
        </Zoom>
      </div>

    </div>
  </section>
);

export default Portfolio;
