import React from 'react';
import './App.css';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import About from './About/About';
import Portfolio from './Portfolio/Portfolio';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

function App() {
  return (
    <>
      <Header />
      <Hero />
      <main id="main">
        <About />
        <Portfolio />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
