/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { BiBullseye, BiTargetLock, BiChevronRight } from 'react-icons/bi';
import Fade from 'react-reveal/Fade';
import './about.css';

const About = () => (
  <section id="about" className="about">
    <div className="container">

      <div className="row">
        <div className="col-lg-4 d-flex align-items-stretch">
          <Fade left>
            <div className="content">
              <h3>About Us</h3>
              <p>
                We build and fund retail property in underserved areas.
                To fund and build retail property in under-served areas.
                To create employment and offer a better
                standard of living for under-served areas.
              </p>
              <div className="text-center">
                <a href="https://africanpg.co.za" className="more-btn">
                  Visit Parent Company
                  {' '}
                  <i className="bx bx-chevron-right" />
                  <BiChevronRight />
                </a>
              </div>
            </div>
          </Fade>
        </div>
        <div className="col-lg-8 d-flex align-items-stretch">
          <div className="icon-boxes d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-xl-6 d-flex align-items-stretch">
                <Fade right>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-receipt" />
                    <BiTargetLock />
                    <h4>Mission</h4>
                    <p>
                      To fund and build retail property in under-served areas.
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-xl-6 d-flex align-items-stretch">
                <Fade right>
                  <div className="icon-box mt-4 mt-xl-0">
                    <BiBullseye />
                    <h4>Vision</h4>
                    <p>
                      To create employment and offer a better
                      standard of living for under-served areas.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
);

export default About;
